
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Success",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    Header,
    Footer,
  },
  data() {
    return {
      store: useStore(),
      rating: 0,
      comments: "",
      loading: false,
      feedbackSuccess: false,
      paymentChecked: false,
      paymentMessage: "",
      paymentStatus: "",
      activeIcons: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
    };
  },
  methods: {
    setFeedbackRating(index: number) {
      for (let i = 0; i <= this.activeIcons.length - 1; i++) {
        this.activeIcons[i].isActive = false;
      }
      this.activeIcons[index].isActive = true;
      this.rating = index + 1;
    },
    async sendFeedback() {
      try {
        this.loading = true;
        const res = await this.store.dispatch("sendFeedback", {
          rating: this.rating,
          comments: this.comments,
        });
        if (res === 200) {
          this.feedbackSuccess = true;
        }
        this.loading = false;
      } catch (e) {
        console.log(e.response);
      }
    },
    async checkPayment() {
      try {
        const res = await this.store.dispatch(
          "checkPayment",
          this.$route.params.identifier || this.$route.query.identifier
        );
        // console.log("check payment");
        // console.log(res);
        this.paymentChecked = true;
        this.paymentMessage = res.data.message;
        this.paymentStatus = res.data.data;
      } catch (e) {
        console.log(e.response);
      }
    },
  },
  mounted() {
    // API call to get party payment confirmation and send emails
    this.checkPayment();
  },
});
